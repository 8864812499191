<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:px-20 space-y-7 w-full">
        <h1 class="lg:text-3xl text-2xl font-semibold mb-6">Sửa hỗ trợ</h1>
        <div class="breadcrumb-area py-0 flex items-center justify-content-between">
          <div class="breadcrumb">
            <ul class="m-0">
             <li>
                <router-link :to="{name: 'TicketList'}">
                  Hòm thư hỗ trợ
                </router-link>
              </li>
              <li class="active">
                Sửa hỗ trợ
              </li>diện
            </ul>
          </div>
        </div>
        <div v-if="message">
          <alert :message="message" :status="status"/>
        </div>
        <form v-on:submit.prevent="updateTicket" class="grid grid-cols-1 gap-3">
          <div>
            <label for="title" class="font-semibold">Tiêu đề (*)</label>
            <input v-model="ticket.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                   required>
          </div>
          <div>
            <label for="title" class="font-semibold">Chuyên mục (*)</label>
            <select v-model="ticket.category">
              <template v-for="category in categories" :key="'category' + category.key">
                <option :value="category.key" v-if="!category.sub">{{ category.label }}</option>
                <optgroup v-if="category.sub" :label="category.label">
                  <option v-for="subCategory in category.sub" :key="'subCategory' + subCategory.key"
                          :value="subCategory.key">{{ subCategory.label }}
                  </option>
                </optgroup>
              </template>
            </select>
          </div>
          <div>
            <label for="content" class="font-semibold">Nội dung (*)</label>
            <editor :full="true" id="content" v-model:content="ticket.content"/>
          </div>
          <div class="border-t flex justify-center border-gray-100 p-6">
            <button :disabled="disabled" type="submit" class="button lg:w-1/3 hover:bg-blue-800">Cập nhật</button>
          </div>
        </form>
      </div>
    </div>

    <alert :message="message"/>
  </div>
</template>

<script>
import Editor from "../Editor";
import ApiService from "../../core/services/api.service";
import {
  getTicketCategories,
} from "../../core/services/utils.service";

export default {
  name: "TicketEdit",
  components: {Editor},
  data() {
    return {
      id: null,
      categories: [],
      ticket: {
        title: "",
        content: "",
        category: ""
      },
      disabled: false
    }
  },
  methods: {
    loadTicket() {
      let query = `query($id: ID!) {
        ticket(id: $id) {
          id
          title
          content
          category
          status
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.ticket) {
              this.ticket = data.data.ticket;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateTicket() {
      this.disabled = true;
      this.message = "";

      let query = `mutation($id: ID!, $title: String, $content: String, $category: String) {
        updateTicket(input: {
          id: $id,
          title: $title,
          category: $category,
          content: $content
        }) {
          id
        }
      }`;

      ApiService.graphql(query, this.ticket)
          .then(({data}) => {
            if (data.data && data.data.updateTicket) {
              this.$toast.success("Cập nhật ticket thành công");
              this.$router.push({name: 'TicketList'});
            } else {
              this.disabled = false;
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.disabled = false;
             this.$toast.error(response.message);
          });
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.categories = getTicketCategories();
    this.loadTicket();
  }
}
</script>
